
          @import "src/assets/scss/additionals.scss";
        
.Form {
  display: grid;
  row-gap: 2rem;
}

.Email {
  @include p1;

  display: flex;
  column-gap: 0.25rem;

  &Title {
    @include reset;

    font-weight: bold;
  }

  &Content {
    @include reset;
  }
}

.Checkbox {
  color: $darkgrey;
}

.PrivacyCheckbox {
  column-gap: 0.75rem;

  span {
    @include tag1;
  }
}

.FieldDesc {
  @include reset;
  @include tag1;
}
