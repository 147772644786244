
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  row-gap: 2.5rem;

  @include media-md {
    row-gap: 4rem;
  }
}

.MainTitle {
  @include reset;
  @include h2;

  font-weight: normal;
}

.List {
  display: grid;
  row-gap: 2.5rem;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }

  @include media-md {
    row-gap: 4rem;
  }
}
