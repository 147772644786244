
          @import "src/assets/scss/additionals.scss";
        
.Root {
  padding: 0 !important;

  svg {
    @include media-md {
      width: 1.25rem;
      height: 1.25rem;
    }
  }
}
