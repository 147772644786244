
          @import "src/assets/scss/additionals.scss";
        
.Wrapper {
  min-width: 200px !important;

  .InputControl {
    border: 0.0625rem solid $grey;
    border-radius: $fieldBorderRadius;

    &.Focused {
      border-color: $alt;
    }

    &.Error {
      color: $error;

      border-color: $error;
    }
  }

  .Input {
    @include btn;

    padding: 1.125rem 0.5rem 0.25rem;

    font-family: $Roboto !important;
    color: $black;

    border-radius: $fieldBorderRadius;
    background-color: #ffffff;

    @include media-md {
      padding: 1.25rem 0.75rem 0.5rem;
    }
  }

  .Label {
    top: -0.25rem;
    left: -0.25rem;

    font-size: 1em;
    font-family: $Roboto !important;
    color: $darkgrey;

    @include media-md {
      top: 0;
      left: 0;
    }

    &.Focused {
      color: $darkgrey;
    }

    &.Error {
      color: $error;
    }

    .Asterisk {
      color: $error;
    }
  }

  .Helper {
    font-family: $Roboto;

    &.Error {
      color: $error;
    }
  }
}
