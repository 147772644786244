
          @import "src/assets/scss/additionals.scss";
        
.Footer {
  position: relative;
  z-index: 1;

  padding: 2.5rem 0;

  background-color: $black;

  @include media-sm {
    padding: 2rem 0;
  }

  @include media-md {
    padding: 2.5rem 0;
  }
}

.Container {
  display: grid;
  row-gap: 1.5rem;

  @include media-sm {
    grid-template-columns: 1fr auto 1fr;
    column-gap: 4.25rem;
    align-items: start;
    justify-content: space-between;
  }

  @include media-md {
    grid-template-rows: 1fr auto;
    grid-template-columns: auto auto 1fr;
    justify-content: space-between;
  }
}

.Logos {
  display: flex;
  justify-content: space-between;
  justify-self: stretch;

  @include media-md {
    column-gap: 1rem;
  }

  img {
    max-width: 5rem;
  }
}

.Divider {
  @include media-sm {
    grid-row: 1 / span 2;
    grid-column: 2;
  }

  @include media-md {
    grid-row: 1 / span 2;
    grid-column: 2;
  }
}

.Contacts {
  @include lreset;

  display: grid;
  row-gap: 1.5rem;
  justify-items: start;

  color: #ffffff;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
    grid-column: 3;
    column-gap: 2rem;
    align-items: start;
  }

  @include media-md {
    grid-template-columns: repeat(2, 1fr);
    grid-row: 1 / span 2;
    grid-column: 3;
  }
}

.Privacy {
  @include tag1;
  justify-self: start;

  color: $darkgrey;
  text-decoration: none;

  @include media-sm {
    align-self: center;
    justify-self: center;
  }

  @include media-md {
    grid-row: 2;
    grid-column: 1;
    justify-self: center;
  }

  &:hover {
    text-decoration: underline;
  }
}
