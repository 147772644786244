
          @import "src/assets/scss/additionals.scss";
        
.Root {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1300;

  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal {
  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: flex-start;
  width: 100%;
  min-width: 17.5rem;
  max-width: 30rem;
  margin: 5rem 1.25rem;
  padding: 1.5rem;

  border-radius: 2rem;
  background-color: #ffffff;

  @include media-md {
    row-gap: 1.5rem;
    padding: 2rem;
  }

  .Close {
    position: absolute;
    top: 1rem;
    right: 1rem;

    padding: 0;

    @include media-sm {
      top: 1.5rem;
      right: 2rem;
    }
  }
}

.Backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  background-color: rgba(0, 0, 0, 0.5);
}

.Title {
  @include reset;
  @include h4;

  font-weight: 700;
}

.Header {
  padding-right: 2rem;
}

.Body {
  width: 100%;
}
