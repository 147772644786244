
          @import "src/assets/scss/additionals.scss";
        
.Root {
  overflow: hidden;

  border: 1px solid $grey;
  border-radius: 1rem;
}

.Title {
  @include h4;
  @include reset;
  padding: 1rem 2rem;

  font-weight: bold;

  background-color: $lightgrey;
  cursor: pointer;
}

.List {
  border-top: 1px solid $grey;
}
