
          @import "src/assets/scss/additionals.scss";
        
$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("./icons.ttf?c00537e1aa1de178b1a790abbc400bf9") format("truetype"),
url("./icons.woff?c00537e1aa1de178b1a790abbc400bf9") format("woff"),
url("./icons.woff2?c00537e1aa1de178b1a790abbc400bf9") format("woff2");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
    "arrow-right": "\f101",
    "check": "\f102",
    "circle": "\f103",
    "close": "\f104",
    "file": "\f105",
    "filled-play": "\f106",
    "filter": "\f107",
    "play": "\f108",
    "plus": "\f109",
    "user": "\f10a",
);

.icon-arrow-right:before {
    content: map-get($icons-map, "arrow-right");
}
.icon-check:before {
    content: map-get($icons-map, "check");
}
.icon-circle:before {
    content: map-get($icons-map, "circle");
}
.icon-close:before {
    content: map-get($icons-map, "close");
}
.icon-file:before {
    content: map-get($icons-map, "file");
}
.icon-filled-play:before {
    content: map-get($icons-map, "filled-play");
}
.icon-filter:before {
    content: map-get($icons-map, "filter");
}
.icon-play:before {
    content: map-get($icons-map, "play");
}
.icon-plus:before {
    content: map-get($icons-map, "plus");
}
.icon-user:before {
    content: map-get($icons-map, "user");
}
