
          @import "src/assets/scss/additionals.scss";
        
.Root {
  padding: 2rem;

  border-radius: 2rem;
  background-color: #ffffff;

  &.Broadcast {
    padding: 1.5rem;

    @include media-lg {
      padding: 2.5rem;
    }
  }
}
