
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
}

.Wrapper {
  display: grid;

  @include media-md {
    grid-template-columns: 1fr 17.5rem;

    border-radius: 2rem;
    background-color: #ffffff;
  }
}

.Main {
  display: grid;
  row-gap: 3rem;
  padding: 1.5rem;

  @include media-md {
    grid-row: 1;
    grid-column: 1;
    padding: 4rem;

    border-right: 1px solid $grey;
    border-radius: 0;
    background-color: transparent;
  }
}

.Divider {
  &:last-child {
    display: none;
  }
}

.Region {
  display: grid;
  row-gap: 2rem;

  &Title {
    @include reset;
    @include h3;
    font-weight: normal;
  }
}

.Projects {
  display: grid;
  row-gap: 2rem;

  @include media-sm {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }

  @include media-md {
    grid-template-columns: 1fr;
  }

  @include media-lg {
    grid-template-columns: repeat(2, 1fr);
  }
}
