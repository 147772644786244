
          @import "src/assets/scss/additionals.scss";
        
.Chip.Custom {
  @include btn;
  justify-self: start;
  height: auto;
  padding: 0.1875rem 0.5rem;

  font-family: $Roboto;

  border-radius: 0.25rem;

  @include media-md {
    padding: 0.3125rem 0.75rem;

    border-radius: 0.5rem;
  }

  .Label {
    padding: 0;
  }

  &.Blue {
    color: $main1;

    background-color: $main0;
  }

  &.Grey {
    color: $darkgrey;

    background-color: $lightgrey;
  }
}
