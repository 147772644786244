
          @import "src/assets/scss/additionals.scss";
        
.Form {
  display: grid;
  row-gap: 1rem;
  width: 100%;

  @include media-sm {
    row-gap: 1.5rem;
  }
}

.Header {
  @include p1;
  display: flex;
  column-gap: 0.25rem;
}

.Title {
  @include reset;

  font-weight: 700;
}

.Email {
  @include reset;
}

.Body {
  display: grid;
  row-gap: 0.75rem;
}

.Checkbox {
  justify-self: start;

  color: $darkgrey;
}

.Footer {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

.PasswordLink {
  margin-left: auto;

  color: $main3;
}
