
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: block;
  overflow: hidden;
  width: 100%;

  @include media-sm {
    width: 41.25rem;
  }
}

.Slide {
  img {
    @include imgCover;
    display: block;
    height: 10rem;

    border-radius: 1rem;

    @include media-sm {
      height: 23.25rem;
    }
  }
}

.Pagination {
  display: flex;
  column-gap: 0.5rem;
  justify-content: center;
  margin-top: 1rem;
}

.Bullet {
  width: 0.5rem;
  height: 0.5rem;

  border-radius: 50%;
  background-color: $lightgrey;
  cursor: pointer;

  &Active {
    background-color: $main1;
  }
}

.Arrow {
  position: absolute;
  top: 50%;
  z-index: 100;

  display: none;

  cursor: pointer;

  transform: translateY(-50%);

  @include media-sm {
    display: block;
  }

  &Next {
    right: 0;
  }

  &Prev {
    img {
      transform: rotate(180deg);
    }
  }

  &.Disabled {
    display: none;
  }
}
