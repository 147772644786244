
          @import "src/assets/scss/additionals.scss";
        
.Root {
  position: relative;

  background-image: url("~@img/header-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background: linear-gradient(360deg, rgba(#000000, 0.5) 0%, rgba(#000000, 0) 87.1%),
      linear-gradient(0deg, rgba(#000000, 0.33), rgba(#000000, 0.33));
  }
}

.Container {
  position: relative;
  z-index: 1;

  display: grid;
  row-gap: 4rem;

  @include media-md {
    row-gap: 5rem;
  }
}

.Main {
  display: grid;
  row-gap: 2.5rem;
  padding: 2rem 0 0.5rem;

  @include media-sm {
    row-gap: 4rem;
    padding: 4rem 0 0;
  }

  @include media-md {
    row-gap: 5rem;
    padding: 5.75rem 0 0;
  }
}

.Logo {
  width: 100%;
}

.Content {
  display: grid;
  row-gap: 1rem;
  justify-items: center;

  @include media-sm {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.Date {
  @include reset;
  @include h2;

  font-weight: bold;
}

.TitleBlock {
  @include reset;
  display: grid;
  row-gap: 0.5rem;

  text-align: center;

  @include media-sm {
    text-align: left;
  }
}

.Title {
  @include h3;
  @include reset;
}

.Subtitle {
  @include reset;
  @include h4;
  font-weight: normal;
}
