
          @import "src/assets/scss/additionals.scss";
        
.Button {
  &.Root {
    column-gap: 0.5rem;

    font-family: $Roboto;
    text-transform: none;

    border-radius: 5rem;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }

  &.Medium {
    padding: 0.4375rem 0.5rem;

    font-weight: 400;
    font-size: 0.6875rem;
    line-height: 0.875rem;

    @include media-md {
      padding: 0.5625rem 0.75rem;

      font-size: 1rem;
      line-height: 1.125rem;
      letter-spacing: -1%;
    }

    &.Outlined {
      padding: 0.375rem 0.4375rem;

      border-width: 0.0625rem;
      border-style: solid;

      @include media-md {
        padding: 0.5rem 0.6875rem;
      }
    }
  }

  &.Large {
    padding: 0.9375rem 1.5rem;

    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1;
    letter-spacing: -2%;

    @include media-md {
      padding: 1.25rem 2.25rem;

      font-size: 1.5rem;
    }

    &.Outlined {
      padding: 0.875rem 1.4375rem;

      @include media-md {
        padding: 1.1875rem 2.1875rem;
      }
    }
  }

  &.Contained {
    color: #ffffff;

    background-color: $main1;

    &:hover {
      background-color: $main2;
    }

    &:active {
      background-color: $lightgrey;
    }
  }

  &.Outlined {
    color: $black;

    border-color: $main1;
    background-color: #ffffff;

    &:hover {
      color: #ffffff;

      border-color: $main2;
      background-color: $main2;
    }

    &:active {
      color: #ffffff;

      border-color: $main3;
      background-color: $main3;
    }
  }

  &.Pale {
    color: $black;

    background-color: $lightgrey;

    &:hover {
      background-color: #ffffff;
    }

    &:active {
      color: $grey;

      background-color: #ffffff;
    }
  }

  &.Disabled {
    opacity: 0.33;
  }

  .StartIcon,
  .EndIcon {
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 0;
    margin-left: 0;

    i {
      font-size: 0.875rem;

      @include media-md {
        font-size: 1.125rem;
      }
    }

    svg {
      width: 0.875rem;
      height: 0.875rem;

      @include media-md {
        width: 1.125rem;
        height: 1.125rem;
      }
    }
  }
}
