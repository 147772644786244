
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  row-gap: 1.5rem;
}

.Group {
  display: grid;
  row-gap: 1.5rem;
}

.GroupTitle {
  @include reset;
  @include p1;
}

.GroupList {
  @include lreset;

  display: flex;
  flex-wrap: wrap;
  row-gap: 1.5rem;
  column-gap: 2.5rem;
}

.GroupItem {
  overflow: hidden;
  width: 5rem;
  height: 5rem;
  padding: 0.375rem;

  border: 1px solid $grey;
  border-radius: 0.25rem;
}
