/* roboto-regular - latin_cyrillic */
@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto";
  src: local(""),
    url("~@assets/fonts/roboto-v29-latin_cyrillic-regular.woff2") format("woff2"),
    url("~@assets/fonts/roboto-v29-latin_cyrillic-regular.woff") format("woff");
}

/* roboto-500 - latin_cyrillic */
@font-face {
  font-weight: 500;
  font-style: normal;
  font-family: "Roboto";
  src: local(""),
    url("~@assets/fonts/roboto-v29-latin_cyrillic-500.woff2") format("woff2"),
    url("~@assets/fonts/roboto-v29-latin_cyrillic-500.woff") format("woff");
}
/* roboto-700 - latin_cyrillic */
@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "Roboto";
  src: local(""),
    url("~@assets/fonts/roboto-v29-latin_cyrillic-700.woff2") format("woff2"),
    url("~@assets/fonts/roboto-v29-latin_cyrillic-700.woff") format("woff");
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "DrukWide";
  src: local(""),
    url("~@assets/fonts/druk-wide-cyr-bold.woff2") format("woff2"),
    url("~@assets/fonts/druk-wide-cyr-bold.woff") format("woff");
}
