
          @import "src/assets/scss/additionals.scss";
        
.Header {
  position: fixed;
  top: 0;
  z-index: 1000;

  width: 100%;

  border-bottom: 0.0625rem solid $grey;
  background-color: $black;

  .Burger {
    display: flex;

    @include media-md {
      display: none;
    }
  }
}

.Container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;
  padding: 0 1.25rem;

  @include media-md {
    height: 4rem;
  }
}

.Logo {
  display: block;

  @include media-md {
    width: 11.0625rem;
  }
}

.Menu {
  @include lreset;
  display: none;

  @include media-md {
    display: flex;
    column-gap: 1rem;
  }

  .Item {
    padding: 0.5rem;

    font-weight: 400;
    color: #ffffff;
    text-transform: none;
  }
}

.Dropdown {
  position: absolute;
  top: 2.5rem;
  right: 0;
  z-index: 10;

  display: grid;
  row-gap: 1.125rem;
  width: 20rem;
  max-width: 100%;
  padding: 1.125rem 1.5rem;

  border-radius: 0.5rem;
  background-color: #ffffff;
  box-shadow: 0 0.25rem 2.5rem rgba(0, 0, 0, 0.15);

  @include media-md {
    top: 4rem;
  }

  &Group {
    display: grid;

    @include media-md {
      display: none;
    }
  }

  .DropdownItem {
    @include p2;

    justify-content: start;
    width: 100%;
    padding: 0.375rem 0;

    font-weight: 400;
    color: $black;
    text-transform: none;
  }

  .SignOut {
    color: $main2;
  }

  .Divider {
    &:last-child {
      display: none;
    }
  }
}

.DropdownWrapper {
  .UserBtn {
    @include p2;

    display: none;

    color: #ffffff;
    text-transform: none;

    @include media-md {
      display: flex;
    }

    i {
      font-size: 1.125rem;
    }
  }

  &.Hidden {
    @include media-md {
      display: none;
    }
  }
}

.SignOutDivider {
  @include media-md {
    display: none;
  }
}
