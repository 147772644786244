
          @import "src/assets/scss/additionals.scss";
        
.Root {
  overflow: hidden;

  border-radius: 2rem;
}

.Img {
  height: 26.75rem;

  img {
    @include imgCover;
  }
}

.Main {
  display: grid;
  row-gap: 1.5rem;
  padding: 1.5rem;

  background-color: #ffffff;

  @include media-md {
    row-gap: 2rem;
    padding: 2rem;
  }
}

.Header {
  display: grid;
  row-gap: 0.75rem;
}

.Title {
  @include reset;
  @include h3;
}

.Subtitle {
  @include reset;
  font-weight: bold;
  color: $main1;
}

.Content {
  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.Tags {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
}

.Divider {
  margin: 1.5rem 0;
}

.Table {
  @include p1;

  display: grid;
  grid-template-columns: 1fr auto;
  row-gap: 1rem;
  column-gap: 1rem;
}

.CellTitle {
  color: $darkgrey;
}

.CellValue {
  justify-self: end;
}
