
          @import "src/assets/scss/additionals.scss";
        
.Container {
  width: 100%;
  max-width: $xl-width;
  margin: 0 auto;
  padding-right: $xs-padding;
  padding-left: $xs-padding;

  @include media-sm {
    padding-right: $sm-padding;
    padding-left: $sm-padding;
  }

  &.PseudoModal {
    max-width: 30rem;
    padding-right: 0;
    padding-left: 0;
  }

  &.Project {
    max-width: 45rem;
    padding-right: 0;
    padding-left: 0;
  }

  &.Index {
    padding-right: 0;
    padding-left: 0;

    @include media-md {
      padding-right: $sm-padding;
      padding-left: $sm-padding;
    }
  }
}
