
          @import "src/assets/scss/additionals.scss";
        
.Form {
  display: grid;
  row-gap: 1.5rem;

  @include media-md {
    row-gap: 2rem;
  }
}

.Disclaimer {
  @include reset;
}

.Btn {
  justify-self: start;
}
