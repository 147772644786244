
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  row-gap: 2rem;
  padding: 1.5rem;

  border-bottom: 1px solid $grey;

  @include media-md {
    display: grid;
    grid-template-columns: 1fr 11.25rem;
    column-gap: 2rem;
    align-items: start;
  }

  @include media-lg {
    padding: 2rem;
  }

  &:last-child {
    border-bottom: none;
  }
}

.Divider {
  @include media-md {
    grid-column: span 2;
  }
}

.Main {
  display: grid;
  row-gap: 1.5rem;
}

.Time {
  @include h4;
  font-weight: bold;
  color: $main1;

  sup {
    @include tag1;
  }
}

.Content {
  display: grid;
  row-gap: 0.75rem;
  justify-items: start;
}

.Title {
  @include reset;
  @include h4;
  font-weight: bold;
  color: $black;
}

.Desc {
  @include reset;
  @include p1;
}

.MoreBtn.Custom {
  @include p2;
  margin-bottom: -0.75rem;
  padding: 0;

  font-weight: normal;
  color: $main1;
  text-transform: none;
}

.More {
  margin-top: 0.75rem;
  padding: 1.5rem;

  border: 1px solid $grey;
  border-radius: 0.25rem;
}

.BtnWrapper {
  display: grid;
  row-gap: 0.5rem;
  justify-self: center;

  @include media-md {
    row-gap: 0.75rem;
    justify-self: stretch;
  }
}

.Participants {
  @include reset;
  @include tag1;
  color: $darkgrey;
  text-align: center;
}

// .ScheduleBtn {
// }
