
          @import "src/assets/scss/additionals.scss";
        
.Avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    @include imgCover;
  }

  &.Null {
    background-color: $lightgrey;

    img {
      width: 42.5%;
      height: 42.5%;
    }
  }
}
