
          @import "src/assets/scss/additionals.scss";
        
.Chat {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: 100%;
  height: 100%;

  .DeleteButton {
    color: red;
  }

  &Header {
    @include p1;

    padding: 1rem 1.5rem;

    font-weight: bold;

    border-bottom: $grey 1px solid;

    @include media-xl {
      padding: 1.5rem;

      font-size: 1.5rem;
    }
  }

  &Body {
    position: relative;

    &Content {
      position: absolute;
      top: 0;
      left: 0;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      height: 100%;
      padding: 1.5rem;
    }
  }

  &Footer {
    padding: 1rem 1.5rem 1.5rem;

    border-top: $grey 1px solid;

    &Group {
      position: relative;

      &:not(:first-child) {
        margin-top: 0.625rem;
      }
    }
  }

  &Input {
    @include p1;

    width: 100%;
    padding: 0.688rem 3.5rem 0.688rem 1rem;

    border: none !important;
    border-radius: 0.5rem;
    background: $lightgrey;

    &::-webkit-input-placeholder {
      color: $darkgrey;
    }

    &::-moz-placeholder {
      color: $darkgrey;
    }

    &:-moz-placeholder {
      color: $darkgrey;
    }

    &:-ms-input-placeholder {
      color: $darkgrey;
    }
  }

  &Send {
    position: absolute;
    top: 0;
    right: 0;

    width: 2.5rem;
    height: 100%;
    padding: 0.625rem;

    border: none;
    background: none;
    cursor: pointer;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
      object-position: center;
    }
  }

  &Message {
    @include p2;

    position: relative;

    flex: 0 0 auto;
    padding: 0.75rem 1rem;

    border-radius: 0.5rem 0.5rem 0.5rem 0;
    background: #dfe3e9;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: -8px;

      width: 17px;
      height: 13px;

      background: url("~@img/message-left-tale.svg") left bottom / contain no-repeat;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &.Self {
      align-self: flex-end;

      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0.5rem;
      background: $lightgrey;

      &::after {
        right: -8px;
        left: auto;

        background-image: url("~@img/message-right-tale.svg");
        background-position-x: right;
      }
    }
  }

  &Author {
    display: flex;
    flex: 0 0 auto;
    align-items: center;

    font-size: 0.688rem;
    line-height: 1.35;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }

    &.Self {
      align-self: flex-end;
    }

    &Photo {
      align-self: start;
      object-fit: cover;
      width: 1rem;
      height: 1rem;
      margin-right: 0.25rem;

      border-radius: 50%;
      background: $grey;
      object-position: center;
    }

    &Name {
      margin-right: 0.5rem;
    }

    &Date {
      color: $darkgrey;
    }
  }

  .AdminName {
    font-weight: bold;
    color: red;
  }
}

.Query {
  display: grid;
  grid-gap: 0.75rem;

  font-size: 0.688rem;
  line-height: 1.35;

  &Applicant {
    &Position {
      color: $darkgrey;
    }

    &Contact {
      display: block;

      color: inherit;

      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }

      &s {
        &Title {
          margin-bottom: 0.25rem;

          color: $darkgrey;
        }
      }
    }
  }

  &Separator {
    width: 100%;
    height: 1px;
    margin: 0;

    border: none;
    background: $darkgrey;
  }

  &Details {
    &Title {
      margin-bottom: 0.5rem;
    }

    &Group {
      font-size: 0.875rem;
      line-height: 1.3;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }

    &Item {
      &:not(:last-child) {
        margin-bottom: 0.25rem;
      }
    }

    &Hint {
      color: $darkgrey;
    }
  }
}
