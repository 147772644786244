
          @import "src/assets/scss/additionals.scss";
        
.Root {
  position: relative;
  z-index: 1;

  display: block;
  padding: 1.5rem 0 0.75rem;

  @include media-sm {
    padding: 2rem 0;
  }
}

.Block {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 2rem;
  column-gap: 2rem;

  @include media-sm {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.Divider {
  grid-column: span 2;
}

.Group {
  display: flex;
  flex-direction: column;
  row-gap: 1.25rem;
  align-items: center;

  @include media-sm {
    display: grid;
    grid-template-columns: 7.5rem auto;
    column-gap: 1.25rem;
  }

  &Title {
    @include tag1;
    @include reset;
  }

  &List {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    column-gap: 1.25rem;
    align-items: center;

    @include media-sm {
      flex-direction: row;
    }
  }

  img {
    width: 5.625rem;

    @include media-md {
      width: 7.5rem;
    }
  }
}

.Group2 {
  @include media-sm {
    order: 2;
  }
}

.Group3 {
  @include media-sm {
    order: 3;
  }
}
