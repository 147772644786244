
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  row-gap: 1.5rem;
  margin-bottom: 2.5rem;

  @include media-sm {
    grid-template-columns: 1fr 1fr auto;
    column-gap: 2rem;
    align-items: center;
  }

  @include media-md {
    grid-template-columns: repeat(2, 1fr);
  }

  .FilterBtn {
    @include media-md {
      display: none;
    }
  }
}

.Title {
  @include reset;
  @include h2;
}

.Description {
  @include reset;
  @include p1;
}
