
          @import "src/assets/scss/additionals.scss";
        
.Form {
  display: grid;
  row-gap: 1rem;
  width: 100%;

  @include media-sm {
    row-gap: 1.5rem;
  }
}

.Footer {
  display: flex;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
}

.Socials {
  display: flex;
  column-gap: 0.625rem;
  align-items: center;

  &Title {
    @include reset;
    @include tag1;

    color: $darkgrey;
  }

  &List {
    @include lreset;
    display: flex;
    column-gap: 0.75rem;
    align-items: center;
  }

  &Item {
    a {
      display: flex;
    }
  }
}
