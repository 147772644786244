
          @import "src/assets/scss/additionals.scss";
        
.Button {
  .Text {
    padding: 0.625rem 1rem;

    font-weight: normal;
    color: $main1;
    text-align: center;
    text-transform: none;

    background-color: #ffffff;

    transition: all 0.2s ease-out;
  }

  .Icon {
    display: flex;
    align-items: center;
    align-self: stretch;
    justify-content: center;
    width: 2.1875rem;

    color: $main1;

    border-left: 1px solid $main1;
    background-color: #ffffff;

    transition: all 0.25s ease-out;
  }

  &.Root {
    @include scheduleBtn;
    @include reset;
    display: inline-grid;
    grid-template-columns: 1fr 2.1875rem;
    align-items: center;
    overflow: hidden;
    min-width: 10.875rem;
    min-height: 0;

    border: 1px solid $main1;
    border-radius: 5rem;
  }

  &:hover {
    .Text {
      color: #ffffff;

      background-color: $main1;
    }

    .Icon {
      color: #ffffff;

      border-color: #ffffff;
      background-color: $main1;
    }
  }
}
