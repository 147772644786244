
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  row-gap: 2rem;

  @include media-lg {
    grid-template-columns: 1fr auto auto;
    column-gap: 4rem;
  }
}

.Body {
  display: grid;
  row-gap: 1.5rem;
}

.Header {
  display: grid;
  row-gap: 1rem;
}

.HeaderTop {
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
  align-items: center;
}

.DateBlock {
  display: grid;
  row-gap: 0.75rem;

  @include media-sm {
    display: flex;
    column-gap: 0.75rem;
  }
}

.Date {
  @include reset;
  @include h4;

  font-weight: bold;
}

.Divider {
  display: none;

  @include media-sm {
    display: block;
    height: auto;
  }
}

.Time {
  @include reset;
  @include p1;
  font-weight: bold;
  color: $main1;

  sup {
    @include tag1;
  }
}

.DayOfWeek {
  @include reset;
  @include p1;

  color: $darkgrey;
  text-transform: capitalize;
}

.Title {
  @include reset;
  @include h4;
  font-weight: bold;
  text-transform: uppercase;
}

.Description {
  @include reset;
  @include p1;
}

.GlobalDivider {
  display: none;

  @include media-lg {
    display: block;
  }
}

.Footer {
  display: grid;
  row-gap: 1rem;
  justify-content: center;

  @include media-sm {
    display: flex;
    column-gap: 1.5rem;
    align-items: center;
    justify-content: flex-start;
  }

  @include media-lg {
    display: grid;
    row-gap: 1.5rem;
    align-content: start;
  }
}

.Participants {
  @include reset;
  @include tag1;

  color: $darkgrey;
  text-align: center;
}
