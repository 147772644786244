
          @import "src/assets/scss/additionals.scss";
        
.Root {
  column-gap: 0.25rem;
  margin-right: 0 !important;
  margin-left: 0 !important;

  @include media-md {
    column-gap: 0.5rem;
  }

  .Label {
    &.Error {
      color: $error;
    }
  }

  .LabelMUI {
    @include btn;
  }

  .LabelWrapper {
    display: inline-flex;
    column-gap: 0.25rem;
    align-items: center;

    @include media-md {
      column-gap: 0.5rem;
    }
  }

  .Counter {
    color: $grey;
  }
}

.FormControl {
  .Helper {
    &.Error {
      font-family: $Roboto;
      color: $error;
    }
  }
}
