$xs-padding: 1.5rem;
$sm-padding: 2rem;

$xs-width: 320px;
$sm-width: 768px;
$md-width: 1024px;
$lg-width: 1280px;
$xl-width: 1440px;

$retina-dpi: 144dpi;
$retina-dppx: 1.5dppx;
