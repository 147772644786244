
          @import "src/assets/scss/additionals.scss";
        
.Contact {
  display: grid;
  row-gap: 0.75rem;
}

.Title {
  @include reset;
  @include p2;

  color: $darkgrey;
}

.Body {
  display: grid;
  row-gap: 0.75rem;
}

.Name {
  @include reset;
  @include p2;

  font-weight: bold;
}

.Email {
  @include tag1;

  color: #ffffff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
