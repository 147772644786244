
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  row-gap: 2rem;
  padding: 1.5rem 1rem 1rem 1rem;

  color: $black;

  border-radius: 2rem;
  background-color: #ffffff;

  @include media-sm {
    padding: 2rem;
  }

  @include media-md {
    row-gap: 4rem;
    padding: 4rem;
  }
}

.Title {
  @include reset;
  @include h2;
}

.Days {
  display: grid;
  row-gap: 2rem;

  @include media-md {
    row-gap: 4rem;
  }
}
