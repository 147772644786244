
          @import "src/assets/scss/additionals.scss";
        
.Preloader {
  position: relative;

  width: 40px;
  height: 40px;

  &Background {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    background: rgba(255, 255, 255, 0.85);

    &.Rounded {
      border-radius: 0.75em;

      @include media-lg {
        border-radius: 1.5em;
      }
    }
  }

  &Wrapper {
    &First {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 9;

      width: 100%;
      height: 100%;
    }

    &Second {
      position: relative;

      width: 100%;
      height: 100%;
    }

    &Third {
      position: sticky;
      top: 0;

      width: 100%;
      height: 100%;
      max-height: 100vh;
    }
  }

  &Dot {
    position: absolute;
    z-index: 1;

    width: 6px;
    height: 6px;

    border-radius: 50%;
    background: $grey;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
    opacity: 0;

    animation: preloader 1s infinite;

    &:nth-child(1) {
      top: 0;
      left: 17px;
    }

    &:nth-child(2) {
      top: 5px;
      left: 29px;

      animation-delay: 0.125s;
    }

    &:nth-child(3) {
      top: 17px;
      right: 0;

      animation-delay: 0.25s;
    }

    &:nth-child(4) {
      top: 29px;
      left: 29px;

      animation-delay: 0.375s;
    }

    &:nth-child(5) {
      bottom: 0;
      left: 17px;

      animation-delay: 0.5s;
    }

    &:nth-child(6) {
      top: 29px;
      left: 5px;

      animation-delay: 0.625s;
    }

    &:nth-child(7) {
      top: 17px;
      left: 0;

      animation-delay: 0.75s;
    }

    &:nth-child(8) {
      top: 5px;
      left: 5px;

      animation-delay: 0.875s;
    }
  }
}

@keyframes preloader {
  20% {
    opacity: 1;

    transform: scale(1.3);
  }
}
