
          @import "src/assets/scss/additionals.scss";
        
.Captcha {
  position: relative;

  display: flex;
  flex-direction: column;
  grid-gap: 1.25rem;
  justify-content: space-between;
  padding: 0.56rem 0.75rem;

  border: $grey 1px solid;
  border-radius: 0.5rem;
  background: $lightgrey;

  @include media-sm {
    flex-direction: row;
    align-items: center;
    padding: 1.875rem 1.5rem;
  }

  &Message {
    font-size: 0.875rem;
    line-height: 1.3;

    &Hint {
      margin-bottom: 0.25rem;

      font-size: 0.688rem;
      line-height: 1.35;
      color: $darkgrey;
    }
  }

  &Select {
    display: flex;
    grid-gap: 0.25rem;
    align-items: center;
    justify-content: space-between;
  }

  &Button {
    @include media-sm {
      flex: 0 1 9.375rem;
    }
  }

  label {
    position: relative;

    font-size: 0;

    cursor: pointer;

    svg {
      object-fit: contain;
      width: 3rem;
      height: 3rem;

      fill: #ffcc4400;

      transition: 0.3s;
      object-position: center;
    }

    &:hover svg {
      fill: #ffcc4467;
    }

    input[type="radio"] {
      position: absolute;
      top: 0;
      left: 0;

      width: 0;
      height: 0;

      opacity: 0;

      &:checked + svg {
        fill: #ffcc44ff;
      }
    }
  }
}

.TextRed {
  color: #ee5555;
}

.Error {
  box-shadow: 0 0 0 1px #ff2211;
}

.HiddenInput {
  position: absolute;
  z-index: -1;

  width: 100%;
  height: 100%;

  opacity: 0;
}
