
          @import "src/assets/scss/additionals.scss";
        
.Root {
  @include media-md {
    grid-column: 2;
    padding: 4rem 2rem;
  }
}

.Wrapper {
  padding-bottom: 2.5rem;
}

.Filter {
  display: grid;
  row-gap: 2.5rem;
}

.Group {
  display: grid;
  row-gap: 1rem;

  &Title {
    @include reset;

    font-weight: bold;
  }
}
