
          @import "src/assets/scss/additionals.scss";
        
.Item {
  overflow: hidden;

  border: 1px solid $grey;
  border-radius: 1rem;
}

.Img {
  height: 10rem;

  img {
    @include imgCover;
  }
}

.Content {
  display: grid;
  row-gap: 1rem;
  padding: 1rem;

  @include media-md {
    row-gap: 1.5rem;
    padding: 1.5rem;
  }
}

.Title {
  @include reset;
  @include p1;
  font-weight: bold;
}

.PriceBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Price {
  @include reset;
  @include p1;
  color: $main1;
}

.Description {
  @include reset;
}
