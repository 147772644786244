
          @import "src/assets/scss/additionals.scss";
        
.Logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  img {
    @include imgContain;
  }

  &.Null {
    background-color: rgba($darkgrey, 0.5);

    img {
      width: 42.5%;
      height: 42.5%;
    }
  }
}
