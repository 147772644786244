
          @import "src/assets/scss/additionals.scss";
        
.Container {
  display: grid;
  row-gap: 2rem;
  max-width: 75rem;

  @include media-lg {
    grid-template-columns: 53.5rem 1fr;
    column-gap: 4rem;
  }
}

.Header {
  display: grid;
  row-gap: 2rem;
}

.Title {
  @include reset;
  @include h2;
}

.Img {
  overflow: hidden;
  height: 17rem;

  border-radius: 2rem;

  @include media-sm {
    height: 25.3125rem;
  }

  @include media-md {
    height: 30.125rem;
  }

  img {
    @include imgCover;
  }
}

.Divider {
  margin: 1.5rem 0;
}

.Main {
  display: grid;
  row-gap: 2rem;
}

.Content {
  @include p1;

  @include media-sm {
    width: 32.75rem;
  }

  @include media-md {
    width: 41.25rem;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
}

.Footer {
  display: grid;
  row-gap: 1rem;
}
