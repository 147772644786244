
          @import "src/assets/scss/additionals.scss";
        
.Root {
  position: relative;

  display: block;
  overflow: hidden;
  margin-right: -1.5rem;
  margin-left: -1.5rem;

  @include media-md {
    grid-column: span 2;
  }

  @include media-lg {
    margin-right: -2rem;
    margin-left: -2rem;
  }

  :global(.swiper-slide) {
    width: 17.5rem;
  }
}

.Container {
  margin-right: 1.5rem;
  margin-left: 1.5rem;

  @include media-lg {
    margin-right: 2rem;
    margin-left: 2rem;
  }
}

.Expert {
  display: grid;
  grid-template-columns: 3.5rem 1fr;
  row-gap: 0.25rem;
  column-gap: 1rem;
  align-items: start;
  width: 17.5rem;
}

.Img {
  grid-row: 1 / span 2;
  overflow: hidden;
  width: 3.5rem;
  height: 3.5rem;

  border-radius: 50%;
}

.Name {
  @include reset;

  grid-row: 1;
  grid-column: 2;

  font-weight: bold;
}

.Company {
  @include reset;
  @include tag1;

  grid-row: 2;
  grid-column: 2;
}

.Arrow {
  position: absolute;
  top: 50%;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 3.5rem;

  font-size: 1.125rem;

  background-color: $lightgrey;
  cursor: pointer;

  transform: translateY(-50%);

  &Next {
    right: 0;

    padding-left: 0.25rem;

    border-radius: 2rem 0 0 2rem;
  }

  &Prev {
    left: 0;

    padding-right: 0.25rem;

    border-radius: 0 2rem 2rem 0;

    i {
      transform: rotate(180deg);
    }
  }

  &.Disabled {
    display: none;
  }
}

.Moderator {
  @include reset;
  @include tag1;
  grid-column: 2;

  color: $main2;
}
