@mixin reset {
  margin: 0;
  padding: 0;
}

@mixin lreset {
  margin: 0;
  padding: 0;

  list-style: none;
}

@mixin scroll {
  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 0.0625rem;
    background-color: rgba($black, 0.05);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.0625rem;
    background-color: rgba($black, 0.2);
  }
}

@mixin imgCover {
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: center;
}

@mixin imgContain {
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: center;
}
