
          @import "src/assets/scss/additionals.scss";
        
.Time {
  @include reset;
  @include h4;
  font-weight: bold;
  color: $main1;

  sup {
    @include tag1;
    margin-left: 0.25rem;
  }
}
