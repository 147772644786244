
          @import "src/assets/scss/additionals.scss";
        
.Chat {
  overflow: hidden;

  background: $white;
}

.Player {
  position: relative;

  overflow: hidden;

  &Wrapper {
    display: grid;
    grid-template-rows: 20rem 1fr;
    overflow: hidden;
    height: 51.25rem;
    margin: 0 -1.25rem;

    border-radius: 1.5rem;

    @include media-sm {
      grid-template-rows: repeat(2, 1fr);
      margin: 0;
    }

    @include media-md {
      height: 58.75rem;
    }

    @include media-lg {
      grid-template-rows: 1fr;
      grid-template-columns: 2fr 1fr;
      height: 45vw;
      max-height: 45rem;
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  &Blured {
    & > div:first-child {
      filter: blur(1.25rem);
    }
  }
}

.Login {
  position: absolute;
  top: 50%;
  left: 50%;

  display: grid;
  row-gap: 1.5rem;
  width: 80vw;
  max-width: 27.5625rem;
  margin: 1rem;
  padding: 2.5rem;

  border-radius: 1.25rem;
  background-color: #ffffff;

  transform: translate(-50%, -50%);

  &Wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &Content {
    display: grid;
    row-gap: 1rem;
  }

  &Title {
    @include reset;
    @include h4;
  }

  &Description {
    @include reset;
    @include p2;
  }
}

.Poster {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 42px;
  left: 0;
  z-index: 30;

  opacity: 0;
}
