@mixin h1 {
  font-size: 2.25rem;
  line-height: 1;
  letter-spacing: -0.02em;

  @include media-md {
    font-size: 3.375rem;
  }
}

@mixin h2 {
  font-size: 1.75rem;
  line-height: 1.1;
  letter-spacing: -0.02em;

  @include media-md {
    font-size: 2.625rem;
  }
}

@mixin h3 {
  font-size: 1.375rem;
  line-height: 1.2;
  letter-spacing: -0.02em;

  @include media-md {
    font-size: 1.875rem;
  }
}

@mixin h4 {
  font-size: 1.125rem;
  line-height: 1.35;
  letter-spacing: -0.02em;

  @include media-md {
    font-size: 1.5rem;
  }
}

@mixin p1 {
  font-size: 0.875rem;
  line-height: 1.45;

  @include media-md {
    font-size: 1.125rem;
  }
}

@mixin p2 {
  font-size: 0.8125rem;
  line-height: 1.55;

  @include media-md {
    font-size: 0.9375rem;
  }
}

@mixin tag1 {
  font-size: 0.6875rem;
  line-height: 1.65;

  @include media-md {
    font-size: 0.8125rem;
  }
}

@mixin tab-menu {
  font-size: 0.625rem;
  line-height: 0.75rem;
}

@mixin scheduleBtn {
  font-size: 0.875rem;
  font-family: $Roboto;
  line-height: 1;
  letter-spacing: -1%;

  @include media-md {
    font-size: 1.125rem;
  }
}

@mixin btn {
  font-size: 0.6875rem;
  font-family: $Roboto;
  line-height: 0.875rem;

  @include media-md {
    font-size: 1rem;
    line-height: 1.125rem;
    letter-spacing: -1%;
  }
}
