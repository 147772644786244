
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  row-gap: 2.25rem;

  color: $black;

  @include media-md {
    padding: 4rem;
  }
}

.Title {
  @include reset;
}

.List {
  @include lreset;
  display: flex;
  flex-wrap: wrap;
  row-gap: 2rem;
  column-gap: 3rem;
  justify-content: space-between;
  padding-top: 0.75rem;

  @include media-sm {
    justify-content: flex-start;
    padding-top: 1.25rem;
  }

  @include media-md {
    column-gap: 4rem;
  }
}

.Item {
  img {
    width: 5.625rem;

    @include media-sm {
      width: 7.5rem;
    }
  }
}
