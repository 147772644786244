
          @import "src/assets/scss/additionals.scss";
        
.Contacts {
  display: grid;
  row-gap: 0.75rem;
  justify-content: start;
  padding: 2rem;

  border-radius: 0.5rem;
  background-color: $lightgrey;
}

.Website {
  @include h4;
  margin-bottom: 0.25rem;

  overflow-wrap: anywhere;
}

.Address {
  @include reset;
  color: $darkgrey;
  overflow-wrap: anywhere;
}
