
          @import "src/assets/scss/additionals.scss";
        
.Layout {
  display: grid;
  grid-template-rows: 1fr auto;
  overflow: hidden;
  min-height: 100vh;
}

.Main {
  padding: 5.5rem 0 2.5rem;

  background-color: $lightgrey;

  @include media-md {
    padding: 8rem 0 4rem;
  }

  &.Region {
    background-color: #ffffff;
  }

  &.Index {
    padding-top: 2.5rem;

    color: #ffffff;

    background-color: $lightgrey;

    @include media-md {
      padding-top: 4rem;
    }
  }
}
