@mixin media-sm {
  @media (min-width: $sm-width) {
    @content;
  }
}

@mixin media-md {
  @media (min-width: $md-width) {
    @content;
  }
}

@mixin media-lg {
  @media (min-width: $lg-width) {
    @content;
  }
}

@mixin media-xl {
  @media (min-width: $xl-width) {
    @content;
  }
}

@mixin min-w($width) {
  @media (min-width: $width) {
    @content;
  }
}

@mixin max-w($width) {
  @media (max-width: $width) {
    @content;
  }
}

@mixin retina-img {
  @media (min-resolution: $retina-dpi), (min-resolution: $retina-dppx) {
    @content;
  }
}

@mixin reset {
  margin: 0;
  padding: 0;
}
