
          @import "src/assets/scss/additionals.scss";
        
.Divider {
  @include reset;

  border: none;
}

.Vertical {
  width: 1px;
  height: 100%;
}

.Horizontal {
  width: 100%;
  height: 1px;
}

.Grey {
  background-color: $grey;
}

.Darkgrey {
  background-color: $darkgrey;
}
