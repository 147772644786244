
          @import "src/assets/scss/additionals.scss";
        
.Block {
  position: relative;

  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  align-items: flex-start;
  width: 100%;
  min-width: 17.5rem;
  max-width: 30rem;
  padding: 1.5rem;

  border-radius: 2rem;
  background-color: #ffffff;

  @include media-sm {
    row-gap: 1.5rem;
    padding: 2rem;
  }
}

.Title {
  @include reset;
  @include h4;

  font-weight: 700;

  &Small {
    @include p1;
  }
}

.Header {
  padding-right: 2rem;
}

.Body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 1.5rem;
  column-gap: 0.5rem;
  width: 100%;

  @include media-lg {
    column-gap: 2rem;
  }

  & > * {
    grid-column: 1 / span 2;
  }

  &Cols2 {
    & > * {
      grid-column: span 1;
    }
  }
}
