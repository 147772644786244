
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  row-gap: 4rem;
}

.Container {
  display: grid;
  row-gap: 4rem;
}
