$white: #ffffff;
$grey: #d2d9d9;
$lightgrey: #eef0f0;
$darkgrey: #939999;
$black: #232226;
$blue: #1160ff;
$main1: #f0a501;
$main2: #e45927;
$main3: #5564ff;
$main0: rgba(#5564ff, 0.15);
$error: #e45927;
$alt: #404f99;
