
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  row-gap: 2.5rem;

  @include media-lg {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    align-items: start;
  }
}

.Header {
  @include media-lg {
    grid-row: 1;
    grid-column: 1 / span 2;
  }
}

.Partners {
  @include media-lg {
    grid-column: 2;
    order: 3;
  }
}

.Schedule {
  @include media-lg {
    grid-column: 1;
    order: 2;
  }
}

.Player {
  @include media-lg {
    grid-column: span 2;
  }
}
