*,
*::before,
*::after {
  box-sizing: inherit;

  font-family: $Roboto;
}

html {
  box-sizing: border-box;
  min-width: $xs-width;

  font-size: 16px;
  font-family: $Roboto;
  scrollbar-width: 6px;
  scrollbar-color: rgba(#232226, 0.2) rgba(#232226, 0.05);
}

body {
  @include p2;

  height: 100%;
  margin: 0;

  color: #232226;
}

img {
  max-width: 100%;
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

i[class^="icon-"] {
  display: inline-flex;
}

a {
  color: $main1;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
