
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  row-gap: 2.5rem;
  padding: 1.5rem;

  border-radius: 2rem;
  background-color: $black;

  @include media-sm {
    row-gap: 4rem;
    padding: 2rem;
  }

  @include media-md {
    padding: 4rem;
  }
}

.Logo {
  width: 100%;
}

.Title {
  @include reset;
  @include h3;
  font-weight: normal;
}

.Subtitle {
  @include reset;
  @include h4;
  font-weight: bold;

  @include media-sm {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 2rem;
    align-items: center;
  }
}

.Divider {
  display: none;

  @include media-sm {
    display: block;

    background-color: #ffffff;
  }
}

.Days {
  display: grid;
  row-gap: 1.5rem;

  @include media-sm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }
}

.Day {
  display: grid;
  row-gap: 2rem;
  padding: 1.5rem;

  color: $black;

  border-radius: 1rem;
  background-color: #ffffff;

  @include media-sm {
    align-content: start;
  }

  &:hover {
    text-decoration: none;
  }
}

.DayHeader {
  @include h3;
  display: flex;
  column-gap: 1rem;
  align-items: center;
  padding-bottom: 1.25rem;

  font-weight: bold;

  border-bottom: 1px solid $grey;

  @include media-sm {
    column-gap: 1.5rem;
  }
}

.DayDate {
  @include reset;
  color: $main1;
}

.DayDivider {
  background-color: $black;
}

.DayTime {
  sup {
    @include p1;
  }
}

.DayMain {
  display: grid;
  row-gap: 1.5rem;
}

.DayTitle {
  @include reset;
  @include h4;
  font-weight: normal;
}

.DayList {
  @include reset;
  display: grid;
  row-gap: 0.25rem;
  padding-left: 1.25rem;

  li {
    @include p2;
  }
}
