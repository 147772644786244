
          @import "src/assets/scss/additionals.scss";
        
.SelectItem.Custom {
  @include btn;
  min-height: auto;
  padding: 0.375rem 0.25rem;
}

.Selected {
  background-color: red;
}
