
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  row-gap: 2rem;
}

.Title {
  @include h4;
  @include reset;
}

.List {
  display: grid;
  row-gap: 2rem;
}

.Item {
  display: grid;
  row-gap: 2rem;

  @include media-sm {
    grid-template-columns: 2fr 1fr;
    column-gap: 2rem;
    align-items: start;
  }

  @include media-lg {
    grid-template-columns: 1fr;
    row-gap: 1rem;
  }
}

.ItemMain {
  display: grid;
  row-gap: 1rem;
}

.ItemContent {
  display: grid;
  row-gap: 0.25rem;
}

.ItemTitle {
  @include reset;
  @include p1;
  font-weight: bold;
}

.ItemDesc {
  @include p2;
}

.UserList {
  display: grid;
  row-gap: 1.25rem;
}

.User {
  display: grid;
  row-gap: 1rem;

  @include media-lg {
    grid-template-columns: 1fr auto;
    column-gap: 1.5rem;
    align-items: start;
  }
}

.UserBody {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 0.25rem;
  column-gap: 0.5rem;
  align-content: start;
}

.UserAvatar {
  grid-row: 1 / span 2;
  grid-column: 1;
  overflow: hidden;
  width: 2.5rem;
  height: 2.5rem;

  border-radius: 50%;
}

.UserName {
  @include reset;
  @include p1;
  font-weight: bold;
}

.UserPosition {
  @include reset;
  @include tag1;
  color: $darkgrey;
}

.DownloadBtn {
  justify-self: center;

  @include media-sm {
    justify-self: start;
  }
}
