
          @import "src/assets/scss/additionals.scss";
        
.Nav {
  display: grid;
  row-gap: 0.75rem;

  @include media-sm {
    display: flex;
    column-gap: 0.75rem;
    align-items: center;
  }

  @include media-lg {
    position: sticky;
    top: 8rem;

    flex-direction: column;
    align-items: flex-start;
    margin-top: 4.875rem;
  }
}

.Title {
  @include reset;
  @include btn;
  color: $darkgrey;
}

.List {
  @include lreset;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.5rem;

  @include media-lg {
    column-gap: 0.75rem;
  }
}

.Divider {
  display: none;

  @include media-lg {
    display: block;
  }
}

.IndexLink {
  margin-left: auto !important;

  @include media-lg {
    margin-left: 0 !important;
  }
}
