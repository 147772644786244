
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  grid-template-rows: repeat(2, 30rem);
  overflow: hidden;

  border-radius: 2rem;
  background-color: $main1;

  @include media-md {
    grid-template-rows: 100%;
    grid-template-columns: 1fr 18.875rem;
    height: 33.5rem;
  }
}

.Main {
  position: relative;

  overflow: hidden;

  @include media-md {
    border-radius: 0 0.5rem 0.5rem 0;
  }
}

.MainItem {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1.5rem;

  @include media-sm {
    padding: 2.5rem;
  }
}

.Video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  filter: blur(1.25rem);

  iframe,
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center;
  }
}

.MainHeader {
  display: grid;
  row-gap: 0.75rem;

  @include media-sm {
    display: flex;
    column-gap: 1rem;
    justify-content: space-between;
  }
}

.Status {
  @include reset;
  display: flex;
  column-gap: 0.5rem;
  align-items: center;

  i {
    font-size: 1.125rem;

    &.Live {
      color: red;
    }
  }
}

.HeaderTime {
  @include reset;
  display: flex;
  column-gap: 1rem;
}

.MainBody {
  display: grid;
  row-gap: 1.5rem;

  @include media-sm {
    grid-template-columns: 1fr auto;
    column-gap: 2.5rem;
    align-items: end;
  }
}

.MainContent {
  display: grid;
  row-gap: 1rem;
}

.Time {
  @include h4;
  font-weight: bold;

  time {
    @include tag1;
  }
}

.Description {
  @include p1;
  color: #ffffff;
}

.Button {
  justify-self: start;
}

.Side {
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 1.5rem;
  align-items: start;
  padding: 1.5rem;

  color: $black;

  @include media-sm {
    padding: 2.5rem 2rem;
  }
}

.SideHeader {
  @include p1;
  display: flex;
  justify-content: space-between;
}

.Soon {
  @include reset;
  opacity: 0.5;
}

.List {
  @include lreset;
  @include scroll;
  display: grid;
  row-gap: 1.5rem;
  overflow-y: auto;
  margin-right: -1rem;
  padding-right: 1rem;
}

.Item {
  display: grid;
  row-gap: 0.5rem;
  padding-bottom: 1.5rem;

  border-bottom: 1px solid rgba($black, 0.33);

  &:last-child {
    border-bottom: none;
  }
}

.ItemHeader {
  @include p1;
  @include reset;
  font-weight: bold;

  sup {
    @include p2;
  }
}

.ItemDesc {
  @include reset;
  @include tag1;
}
