
          @import "src/assets/scss/additionals.scss";
        
.Root {
  display: grid;
  grid-template-rows: 14.625rem auto;
  overflow: hidden;

  border-radius: 2rem;

  @include media-md {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
  }

  .Btn {
    justify-self: start;

    color: #ffffff;

    border-color: #ffffff;
    background-color: transparent;

    &:hover {
      border-color: $main1;
    }
  }
}

.Img {
  position: relative;

  @include media-md {
    grid-row: 1;
    grid-column: 2;
  }

  img {
    @include imgCover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.Body {
  display: grid;
  row-gap: 2rem;
  padding: 2rem;

  background-color: $main2;

  @include media-md {
    grid-row: 1;
    grid-column: 1;
    padding: 4rem;
  }
}

.Content {
  display: grid;
  row-gap: 1rem;
}

.Title {
  @include reset;
  @include h2;
}

.Desc {
  @include reset;
  @include p1;
}
