
          @import "src/assets/scss/additionals.scss";
        
.Group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 0.75rem;
  column-gap: 0.5rem;
  width: 100%;

  & > * {
    grid-column: 1 / span 2;
  }

  &Cols2 {
    & > * {
      grid-column: span 1;
    }
  }
}
